import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Route, HashRouter as Router, Routes } from "react-router-dom";

import LoginPage from "./Page/LoginPage";
import ResultPage from "./Page/ResultPage";
import { ProtectedRoute } from "./Common/UserService";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes basename={"/"}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/result" element={<ResultPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
