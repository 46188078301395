import Swal from "sweetalert2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";

import UserService from "../Common/UserService";

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    UserService.doLogin(username, password)
      .then(() => {
        navigate("/result");
      })
      .catch(() => {
        Swal.fire("Login Failed!");
      });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleLogin(event);
    }
  };

  return (
    <Container style={{ flex: 1 }}>
      <Container className="ContentLogin">
        <div className="TitleContainer">
          <h5 className="Label">PYC 99</h5>
        </div>

        <div className="FCtrlContainer">
          <div className="FCtrlContent">
            <Form.Control
              name="username"
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        <div className="FCtrlContainer">
          <div className="FCtrlContent">
            <Form.Control
              name="password"
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        <div>
          <Button className="ButtonLogin" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </Container>
    </Container>
  );
};
export default LoginPage;
