import axios from "axios";
import moment from "moment";
import {
  Container,
  Form,
  InputGroup,
  Table,
  Button,
  Modal,
  FormControl,
  Navbar,
  Nav,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheck } from "@fortawesome/free-solid-svg-icons";

import UserService from "../Common/UserService";
import PaginationList from "../Common/PaginationList";
import { plantOption, pageLimitOption } from "../Common/DropdownList";
import LoginPage from "./LoginPage";

export const styles = {
  NavbarStyle: { backgroundColor: "#1f4529" },
  TextStyle: { fontWeight: "bold", color: "#c2ffc7" },
};

const ResultPage = () => {
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [site, setSite] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [limitPage, setLimitPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [showList, setShowList] = useState([]);
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const oneWeekAgo = moment().subtract(7, "days").format("YYYY-MM-DD");

    setToDate(today);
    setFromDate(oneWeekAgo);
  }, []);

  const sv_name = localStorage.getItem("sv_name");
  const access_token = localStorage.getItem("access_token");

  const getList = async (page = 1) => {
    try {
      let res;
      if (searchKeyword) {
        const entity = {
          keyword: searchKeyword,
          page: page,
          limit: +limitPage,
        };
        // console.log(51, entity);
        res = await axios.post(
          `https://pyc99.hslnet.my/api/batching/search`,
          entity
        );
      } else {
        if (site) {
          if (site == 0) {
            res = await axios.get(
              `https://pyc99.hslnet.my/api/batching/get/all/${fromDate}/${toDate}/${page}/${limitPage}`
            );
          } else {
            res = await axios.get(
              `https://pyc99.hslnet.my/api/batching/get/${site}/${fromDate}/${toDate}/${page}/${limitPage}`
            );
          }
        } else {
          res = await axios.get(
            `https://pyc99.hslnet.my/api/batching/get/all/${fromDate}/${toDate}/${page}/${limitPage}`
          );
        }
      }
      // console.log(67, res);
      if (res.data?.data.length > 0) {
        setShowList(res?.data?.data);
        setTotalPage(res?.data?.totalPages);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleChangePlant = (event) => {
    const selectedValue = event.target.value;
    // console.log(selectedValue);
    setSite(selectedValue);
  };

  const handleChangefromDate = (e) => {
    const newfromDate = e.target.value;
    setFromDate(newfromDate);
  };

  const handleChangetoDate = (e) => {
    const newtoDate = e.target.value;
    setToDate(newtoDate);
  };

  const handleClickSearch = () => {
    setShowList([]);
    getList();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleClickSearch(event);
    }
  };

  const handleChangeLimitPage = (event) => {
    const selectedValue = event.target.value;
    // console.log(selectedValue);
    setLimitPage(selectedValue);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
    getList(page);
  };

  const handleTick = (_id) => {
    setShowModal(true);
    setId(_id);
  };

  const handleNo = () => {
    setShowModal(false);
  };

  const handleYes = async () => {
    const entity = {
      id: id,
      review: 1,
      reviewed_by: sv_name,
    };
    try {
      await axios.put(
        "https://pyc99.hslnet.my/api/batching/update/review",
        entity
      );
      setShowModal(false);
      await getList();
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleDownloadExcel = async () => {
    const entity = {
      site: site ? +site : 0,
      fromDate: fromDate,
      toDate: toDate,
      data: [showList],
    };
    axios
      .post(`https://pyc99.hslnet.my/api/batching/export/excel`, entity, {
        responseType: "blob",
      })
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Excel_${toDate}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.error("Error downloading file:", err);
      });
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Confirm Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.doLogout();
        navigate && navigate("/");
      }
    });
  };

  return (
    <>
      {access_token ? (
        <>
          <Navbar style={styles.NavbarStyle}>
            <Container fluid>
              <Nav className="w-100 justify-content-between">
                <Nav.Link>
                  <span style={styles.TextStyle}>Sign in as {sv_name}</span>
                </Nav.Link>
                <Nav.Link onClick={handleLogout}>
                  <span style={styles.TextStyle}>Log Out</span>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>

          <Container fluid className="ContentResult">
            <Form.Label className="Label">PYC 99</Form.Label>
            <br />

            <div className="d-flex justify-content-between align-items-center mb-3">
              <InputGroup>
                {/* SEARCH */}
                <FormControl
                  className="me-3"
                  placeholder="Search Keyword"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {/* PLANT */}
                <InputGroup.Text>Plant</InputGroup.Text>
                <Form.Select
                  className="me-3"
                  value={site}
                  onChange={handleChangePlant}
                >
                  {plantOption.map(({ label, value }, i) => (
                    <option key={i} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
                {/* FROM DATE */}
                <InputGroup.Text>From</InputGroup.Text>
                <Form.Control
                  name="fromDate"
                  type="date"
                  onChange={handleChangefromDate}
                  value={fromDate}
                />
                {/* TO DATE */}
                <InputGroup.Text>To</InputGroup.Text>
                <Form.Control
                  name="toDate"
                  type="date"
                  onChange={handleChangetoDate}
                  value={toDate}
                />
                <Button
                  className="ButtonSearch me-3"
                  onClick={() => handleClickSearch()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
                {/* LIMIT PAGE */}
                <Form.Select
                  className="me-3"
                  value={limitPage}
                  onChange={handleChangeLimitPage}
                >
                  {pageLimitOption.map(({ label, value }, i) => (
                    <option key={i} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>

              <PaginationList
                nPages={totalPage || 1}
                currentPage={currentPage || 1}
                handlePagination={handlePagination}
                // nPages={totalPage}
                // currentPage={currentPage}
                // handlePagination={handlePagination}
              />
            </div>

            {showList.length > 0 ? (
              <Table responsive>
                <thead style={{ fontSize: "12px" }}>
                  <tr>
                    <th>Reviewed</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>D/O No</th>
                    <th>Order No</th>
                    <th>Customer</th>
                    <th>Project</th>
                    <th>Delivery Site</th>
                    <th>Transporter</th>
                    <th>Load Number</th>
                    <th>Progress(m3)</th>
                    <th>Total(m3)</th>
                    <th>Grade</th>
                    <th>Cubic(m3)</th>
                    <th>Slump(mm)</th>
                    <th>Additives</th>
                    <th>Mix Design</th>
                    <th>P/O No</th>
                    <th>Lorry Code</th>
                    <th>Lorry Plate</th>
                    <th>Driver</th>
                    <th>Remark</th>
                    <th>Batched By</th>
                    <th>Review By</th>
                  </tr>
                </thead>
                <tbody>
                  {showList.map(({ _id, data, review, reviewed_by }, i) => (
                    <tr>
                      <th>
                        {review == 1 ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ color: "black" }}
                          />
                        ) : (
                          <Form.Check
                            type="checkbox"
                            onChange={() => handleTick(_id)}
                          />
                        )}
                      </th>
                      <th className="ThStyle">{data.date}</th>
                      <th className="ThStyle">{data.time}</th>
                      <th className="ThStyle">{data.do_number}</th>
                      <th className="ThStyle">{data.order_no}</th>
                      <th className="ThStyle">{data.customer}</th>
                      <th className="ThStyle">{data.project}</th>
                      <th className="ThStyle">{data.delivery_site}</th>
                      <th className="ThStyle">{data.transporter}</th>
                      <th className="ThStyle">{data.load_number}</th>
                      <th className="ThStyle">{data.progress}</th>
                      <th className="ThStyle">{data.total_order}</th>
                      <th className="ThStyle">{data.grade}</th>
                      <th className="ThStyle">{data.cubic}</th>
                      <th className="ThStyle">{data.slump}</th>
                      <th className="ThStyle">{data.additives}</th>
                      <th className="ThStyle">{data.mix_design}</th>
                      <th className="ThStyle">{data.po_no}</th>
                      <th className="ThStyle">{data.lorry_code}</th>
                      <th className="ThStyle">{data.lorry_plate}</th>
                      <th className="ThStyle">{data.driver}</th>
                      <th className="ThStyle">{data.remark}</th>
                      <th className="ThStyle">{data.batched_by}</th>
                      <th className="ThStyle">{reviewed_by}</th>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Table responsive>
                <thead style={{ fontSize: "12px" }}>
                  <tr>
                    <th>Reviewed</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>D/O No</th>
                    <th>Order No</th>
                    <th>Customer</th>
                    <th>Project</th>
                    <th>Delivery Site</th>
                    <th>Transporter</th>
                    <th>Load Number</th>
                    <th>Progress(m3)</th>
                    <th>Total(m3)</th>
                    <th>Grade</th>
                    <th>Cubic(m3)</th>
                    <th>Slump(mm)</th>
                    <th>Additives</th>
                    <th>Mix Design</th>
                    <th>P/O No</th>
                    <th>Lorry Code</th>
                    <th>Lorry Plate</th>
                    <th>Driver</th>
                    <th>Remark</th>
                    <th>Batched By</th>
                    <th>Review By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="ThStyle" colSpan={25}>
                      NO DATA. PLEASE SELECT PLANT OR FROM DATE AND TO DATE
                    </th>
                  </tr>
                </tbody>
              </Table>
            )}

            {showList.length > 0 && (
              <div className="d-flex justify-content-between align-items-flex-end mb-3">
                <Button onClick={handleDownloadExcel}>Download Excel</Button>
              </div>
            )}

            <Modal show={showModal} onHide={handleNo}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>Confirm to review this item?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleNo}>
                  No
                </Button>
                <Button variant="primary" onClick={handleYes}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </>
      ) : (
        <LoginPage />
      )}
    </>
  );
};
export default ResultPage;
