export const plantOption = [
  { label: "", value: 0 },
  { label: "Muara Tabuan", value: 1 },
  { label: "Paloh", value: 2 },
  { label: "Stephen Yong", value: 3 },
  { label: "Samariang", value: 4 },
];

export const pageLimitOption = [
  { label: 30, value: 30 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
